<template>
	<basePopup :okFunc="edit" :title="title" okText="수정" size="md">
		<h6 class="mt-2">최소 매칭조건</h6>
		<div class="flex">
			<b-form-radio
				v-for="v in matchingClassOpts"
				class="mr-4"
				v-model="input.matchingClass"
				name="popupMatchingClass"
				:value="v"
			>
				{{ v }}
			</b-form-radio>
		</div>

		<div class="flex mt-2">
			<b-form-radio
				v-for="v in followerOpts.slice(1)"
				class="mr-4"
				v-model="input.follower"
				name="popupFollower"
				:value="v.value"
			>
				{{ v.label }}
			</b-form-radio>
		</div>

		<h6 class="mt-2">매칭상태</h6>
		<vSelect v-model="input.matchingStatus" :clearable="false" :options="matchingOpts.slice(1)" />
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

export default {
	props: {
		cName: { default: 'sponsoredPopup' },
		matchingOpts: { default: '' },
	},
	components: { basePopup },
	data() {
		return {
			title: '',
			isShow: false,

			matchingClassOpts: ['N', 'B', 'A', 'S'],
			followerOpts: [
				{ label: '전체', value: -1 },
				{ label: '조건없음', value: 0 },
				{ label: '1만', value: 1 },
				{ label: '2만', value: 2 },
				{ label: '3만', value: 3 },
				{ label: '4만', value: 4 },
				{ label: '5만', value: 5 },
			],

			origin: '',
			input: {
				matchingClass: '',
				follower: '',
				matchingStatus: '',
			},
		}
	},
	methods: {
		open(item) {
			this.origin = item
			const func = () => {
				this.title = `${item.comName} ${item.dealName}`
				this.input.matchingClass = item.matchingClass
				this.input.follower = item.follower
				this.input.matchingStatus = getOptsFromLabel(this.matchingOpts, item.matchingStatus)
				this.isShow = true
			}
			if (this.matchingOpts) func()
			else
				getConfig('matching_status').then(res => {
					this.matchingOpts = res.matching_status
					func()
				})
		},
		getMatchingStatusData(originVal) {
			const status = getDropdownValue(originVal)

			switch (status) {
				case 'DISABLE_IMPOSSIBLE_ENCORE':
					return {
						status: 'DISABLE',
						isEncoreEnable: false,
					}
				case 'DISABLE_POSSIBLE_ENCORE':
					return {
						status: 'DISABLE',
						isEncoreEnable: true,
					}
				case '':
					return {}
			}
			return { status }
		},
		edit() {
			const input = this.input,
				matchingClass = input.matchingClass,
				follower = getDropdownValue(input.follower),
				status = getDropdownValue(input.matchingStatus),
				origin = this.origin,
				e = []
			if (!matchingClass || follower === '') e.push('최소 매칭 조건을 설정해주세요')
			if (!status) e.push('매칭 상태를 설정해주세요')
			if (e.length) alert.w(e)
			else
				putApi('api/backoffice/sponsorHandle/updateMatchDetail', {
					productMatchingInfoSeq: this.origin.productMatchingInfoSeq,
					productGroupIdx: this.origin.productGroupIdx,
					isEncoreEnable: true,
					matchingClass,
					follower,
					...this.getMatchingStatusData(input.matchingStatus),
				}).then(() => {
					alert.s('협찬 상태 변경에 성공했습니다')
					this.isShow = false
					reload(this.p)
				})
		},
	},
}
</script>
