<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-4/11">
					<h6>최소 매칭 조건</h6>
					<div class="flex">
						<b-form-radio class="mr-4" v-model="opt.matchingClass" name="matchingClass" value="-1">
							전체
						</b-form-radio>
						<b-form-radio
							v-for="v in matchingClassOpts"
							class="mr-4"
							v-model="opt.matchingClass"
							name="matchingClass"
							:value="v"
						>
							{{ v }}
						</b-form-radio>
					</div>
					<div class="flex mt-2">
						<b-form-radio
							v-for="v in followerOpts"
							class="mr-4"
							v-model="opt.follower"
							name="follower"
							:value="v.value"
						>
							{{ v.label }}
						</b-form-radio>
					</div>
				</div>
				<div class="w-7/11 flex">
					<div class="w-1/5 pr-4">
						<h6>매칭상태</h6>
						<vSelect v-model="opt.matchingStatus" :clearable="false" :options="matchingOpts" />
					</div>
					<div class="w-1/5 pr-4">
						<h6>내돈내산가능여부</h6>
						<vSelect v-model="opt.selfDealStatus" :clearable="false" :options="selfDealOpts" />
					</div>
					<div class="w-1/5 pr-4">
						<h6>키워드 검색</h6>
						<vSelect v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
					</div>
					<div class="w-2/5">
						<h6>&nbsp;</h6>
						<div class="flex">
							<b-form-input
								class="mr-4"
								v-model.trim="opt.searchKeyword"
								name="keyword"
								placeholder="검색어"
								@keyup.enter="search"
							/>
							<b-button class="w-30 px-2" @click="search">검색</b-button>
						</div>
					</div>
				</div>
			</div>
		</searchCard>

		<b-card>
			<div class="flex">
				<vSelect
					class="w-60 mr-4"
					v-model="changeAllStatus"
					:clearable="false"
					:options="matchingOpts.slice(1)"
				/>
				<b-button class="" @click="changeAll">일괄 상태 변경</b-button>
				<b-button class="absolute right-0 -top-1.5" @click="reload" size="sm" variant="transparent">
					<b-button class="mr-4" @click="excelExport">엑셀 다운로드 요청</b-button>
					<b-icon icon="arrow-clockwise" scale="1.2" />
				</b-button>
			</div>
			<tb :inf="inf" :pr="ths" :res="res" checkBoxKey="productGroupIdx" limit="30">
				<template #dealLink="{ h, v }">
					<a v-if="v.sampleDeal" :href="`${dealHost}/${v.sampleDeal}`" target="_blank">{{ v.sampleDeal }} </a>
				</template>
				<template #sponsoredMemo="{ h, v }">
					<b-button @click.stop.prevent="openMemo(v, h)" size="sm" variant="no">
						<b-icon font-scale="1" icon="stickies" />
					</b-button>
				</template>
			</tb>
		</b-card>
		<sponsoredPopup :matchingOpts="matchingOpts" :pr="ths" />
	</div>
</template>

<script>
import sponsoredPopup from 'pages/sponsoredPopup'

const matchingClassOpts = ['N', 'B', 'A', 'S'],
	followerOpts = [
		{ label: '전체', value: -1 },
		{ label: '조건없음', value: 0 },
		{ label: '1만', value: 1 },
		{ label: '2만', value: 2 },
		{ label: '3만', value: 3 },
		{ label: '4만', value: 4 },
		{ label: '5만', value: 5 },
	],
	selfDealOpts = [
		{ label: '전체', value: '' },
		{ label: '가능', value: true },
		{ label: '불가능', value: false },
	],
	searchKeyOpts = [
		{ label: 'idx', value: 'productGroupIdx' },
		{ label: '카테고리', value: 'category' },
		{ label: '업체명', value: 'comName' },
		{ label: '핫딜명', value: 'dealName' },
		{ label: '샘플핫딜', value: 'sampleDeal' },
	],
	defOpt = {
		matchingClass: -1,
		follower: -1,
		matchingStatus: selfDealOpts[0],
		selfDealStatus: selfDealOpts[0],
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	components: { sponsoredPopup },
	data() {
		let now = new Date()
		const next = getMonth(now, 1, true).getMonth() + 1,
			twoMonthLater = getMonth(now, 2, true).getMonth() + 1
		now = now.getMonth() + 1

		return {
			matchingClassOpts,
			followerOpts,
			matchingOpts: [],
			selfDealOpts,
			searchKeyOpts,
			opt: { ...defOpt },
			defOpt,
			changeAllStatus: {},

			inf: [
				{ model: 'checkAll' },
				{
					title: 'idx',
					model: 'button',
					text: v => v.productGroupIdx,
					func: this.openEditPage,
					bVariant: 'no',
					class: 'text-primary p-0',
					size: 2,
				},
				{ title: '등록일', key: 'updateDt', size: 3 },
				{
					title: '카테고리',
					key: 'category',
					size: 5,
				},

				{ title: '업체명', key: 'comName', size: 5 },
				{ title: '핫딜명', key: 'dealName', size: 10 },
				{ title: '샘플 핫딜', key: 'sampleDeal', model: 'dealLink', size: 2 },
				{
					title: '매칭 조건',
					model: 'button',
					text: v => v.matchingCondition,
					func: this.openSponsoredPopup,
					bVariant: 'no',
					class: 'text-normal p-0',
					size: 2,
				},
				{
					title: '매칭상태',
					model: 'button',
					text: v => v.matchingStatus,
					func: this.openSponsoredPopup,
					bVariant: 'no',
					class: 'text-normal p-0',
					size: 4,
				},
				{ title: '노출 등급', key: 'class', size: 2 },
				{
					title: '이슈/체크 사항',
					model: 'button',
					text: v => v.memo,
					func: (v, h) => {
						this.openMemo(v, h)
					},
					bVariant: 'no',
					class: 'text-normal p-0',
					size: 8,
				},
				{ title: now + '월<br>협찬', key: 'current', size: 2 },
				{ title: next + '월<br>협찬', key: 'next', size: 2 },
				{ title: twoMonthLater + '월<br>협찬', key: 'twoMonthLater', size: 2 },
				{ title: '딜 불가기간', key: 'dealRestrictedDate', size: 4 },
				{ title: '상품 수수료(%)<br>(전체/인플/핫트)', key: 'fee', size: 5 },
				{ title: '내돈<br>내산', key: 'isSelfDeal', size: 2 },
				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: this.openSponsoredPopup,
				},
				{ title: '메모', model: 'sponsoredMemo', size: 2 },
			],
			lastOpt: {},
			res: {},

			hottistTypeOpts: [],
			contentQualityOpts: [],
			contentCategoryOpts: [],
			pageKey: 'hottistExamList',

			dealHost,
		}
	},
	methods: {
		getMatchingStatusData(originVal) {
			const status = getDropdownValue(originVal)

			switch (status) {
				case 'DISABLE_IMPOSSIBLE_ENCORE':
					return {
						status: 'DISABLE',
						isEncoreEnable: false,
					}
				case 'DISABLE_POSSIBLE_ENCORE':
					return {
						status: 'DISABLE',
						isEncoreEnable: true,
					}
				case '':
					return {}
			}
			return { status }
		},
		search() {
			const opt = this.opt,
				isWholeClass = opt.matchingClass == -1,
				isWholeFollower = opt.follower == -1,
				selfDealStatus = getDropdownValue(opt.selfDealStatus),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword,
				data = {
					matchingClass: isWholeClass ? 'N' : opt.matchingClass,
					isWholeClass,
					follower: isWholeFollower ? 0 : opt.follower,
					isWholeFollower,
					paging: getPaging(0, 30),
					...this.getMatchingStatusData(opt.matchingStatus),
					matchingStatusCode: getDropdownValue(opt.matchingStatus),
				}

			if (selfDealStatus !== '') data.selfDealStatus = selfDealStatus

			if (searchVal) data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)

			this.lastOpt = data
			this.changePage()
		},
		changePage(page = 1, ex = false) {
			this.lastOpt.paging.pageNo = page - 1

			postApi('api/backoffice/sponsorSelect/sponsorStatusList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.updateDt = v.updateDt.substr(2)

						v.fee = `${v.totalFee} / <span class='fw-700'>${v.hottistFee}</span> / ${v.hottFee}`

						if (v.monthlyMaxCount == 99999999) v.monthlyMaxCount = '∞'
						;['current', 'next', 'twoMonthLater'].map(
							k => (v[k] = `${v[k + 'Request']}/${v.monthlyMaxCount}`)
						)

						v.dealRestrictedDate = v.dealRestrictedDate.join('\r\n')

						if (v.class == 'Z') v.trClass = 'text-gray-light'
						return v
					})
					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()

			let matchingCondition = opt.isWholeClass ? '전체' : opt.matchingClass
			matchingCondition += '/'
			if (opt.isWholeFollower) matchingCondition += '전체'
			else matchingCondition += getOptsLabel(followerOpts, opt.follower)
			ex.key('최소 매칭 조건', matchingCondition)
			ex.dropdown(opt.matchingStatusCode, this.matchingOpts, '매칭 상태', '전체')
			if (typeof opt.selfDealStatus != 'undefined')
				ex.key('내돈내산 가능 여부', opt.selfDealStatus ? '가능' : '불가능')
			ex.search(opt.searchKey, this.searchKeyOpts)
			ex.go(this)
		},
		changeAll() {
			const list = this.tb.getChecked(v => {
				return {
					productGroupIdx: v.productGroupIdx,
					productMatchingInfoSeq: v.productMatchingInfoSeq,
				}
			})
			if (list.length) {
				const base = { isEncoreEnable: true, ...this.getMatchingStatusData(this.changeAllStatus) }
				putApi(
					'api/backoffice/sponsorHandle/updateMatchStatus',
					list.map(data => {
						return {
							...base,
							...data,
						}
					})
				).then(() => {
					alert.s('매칭 상태 변경에 성공했습니다')
					refresh()
				})
			} else alert.w('상태를 변경할 상품을 선택해주세요')
		},
		reload() {
			reload(this)
		},
		openEditPage(item) {
			window.open('/product_add/' + item.productGroupIdx)
		},
		openSponsoredPopup(item) {
			this.sponsoredPopup.open(item)
		},

		openMemo(v, h) {
			openMemoPopup('PRODUCT_GROUP', v, h)
		},
	},
	created() {
		getConfig('matching_status').then(res => {
			this.matchingOpts = res.matching_status
			this.changeAllStatus = res.matching_status[1]
		})
		this.search()
	},
}
</script>
